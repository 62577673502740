.f-11 {font-size: 11px}
.fb-11 {font-size: 11px; font-weight: bold}
.f-12 {font-size: 12px}
.fb-12 {font-size: 12px; font-weight: bold}
.f-13 {font-size: 13px}
.fb-13 {font-size: 13px; font-weight: bold}
.f-14 {font-size: 14px}
.fb-14 {font-size: 14px; font-weight: bold}
.f-15 {font-size: 15px}
.fb-15 {font-size: 15px; font-weight: bold}
.f-18 {font-size: 18px}
.fb-18 {font-size: 18px; font-weight: bold}
.f-24 {font-size: 24px}
.fb-24 {font-size: 24px; font-weight: bold}
.f-30 {font-size: 30px}
.fb-30 {font-size: 30px; font-weight: bold}
.f-40 {font-size: 40px}
.fb-40 {font-size: 40px; font-weight: bold}
.f-50 {font-size: 50px}
.fb-50 {font-size: 50px; font-weight: bold}
.f-60 {font-size: 60px}
.fb-60 {font-size: 60px; font-weight: bold}
.f-80 {font-size: 80px}
.fb-80 {font-size: 80px; font-weight: bold}

.rbc-time-content {
  display: none !important;
}

.rbc-time-header {
  flex: 1 !important;
}

.rbc-event {
  background-color: transparent;
  color: #646777;
  &.rbc-selected {
    background-color: transparent;
  }
}

.form__form-group-label {
  &.standalone {
    margin-top: 7px!important;
    padding-right: 0;
    width: 70px !important;
    margin-right: 6px !important;
  }
}

.red-dot {
  position: absolute;
  right: -6px;
  top: 0;

  & > div {
    position: relative;

    &:before {
      background-color: rgba(224, 83, 111, 0.2);
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      animation: beforePulse 1.5s infinite;
    }

    &:after {
      height: 7px;
      width: 7px;
      background-color: #e0536f;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.form-row {
  //margin-bottom: 4px;
}

.form-field {
  margin-right: 12px;
}

.form-field-last {
  margin-right: 0;
}

.moving-form-footer {
  position: absolute;
  left: 36px;
  bottom: 20px;
  font-size: 11px;
  color: #909090;
}

.house-footer {
  position: absolute;
  right: 20px;
  bottom: 5px;
  font-size: 11px;
  color: #909090;
  text-align: right;
}

.new-house-footer {
  position: absolute;
  right: 20px;
  font-size: 11px;
  color: #909090;
  text-align: right;
}

.search-pane {
  width: 200px;
  height: calc(100vh - 100px);
  margin-top: -8px;
  padding-bottom: 0;
}

table.print-table {
  width: 890px;
  border-collapse: collapse;
  border-spacing: 0;
  //border: 1px solid #909090;
  //font-size: 12px;
  & tr {
    //border: 1px solid #909090;
  }
  & td {
    vertical-align: top;
    border: 1px solid #000;
    padding: 0 8px;
    & .label {
      min-height: 1em;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    & .label2 {
      min-height: 1em;
      font-weight: bold;
      padding-bottom: 4px;
    }
    & .label3 {
      min-height: 1em;
      padding-top: 2px;
      padding-bottom: 2px;
    }
    & .value {
      min-height: 1em;
      font-weight: bold;
      padding-bottom: 4px;
      white-space: pre-line;
    }
    & .value2 {
      min-height: 50px;
      font-weight: bold;
      padding-bottom: 4px;
      white-space: pre-line;
    }
    & .value3 {
      min-height: 100px;
      font-weight: bold;
      padding-bottom: 4px;
      white-space: pre-line;
    }
    & .value4 {
      min-height: 1em;
      font-weight: bold;
      padding-top: 2px;
      padding-bottom: 2px;
      white-space: pre-line;
    }
    & .value5 {
      min-height: 40px;
      font-weight: bold;
      padding-bottom: 4px;
      white-space: pre-line;
    }
    & .value6 {
      min-height: 1em;
      padding-top: 2px;
      padding-bottom: 2px;
      white-space: pre-line;
    }
  }
  & td.no-border {
    border-top: none;
    border-bottom: none;
  }
  & td.center {
    text-align: center;
    vertical-align: middle;
  }
  & td.centerTop {
    text-align: center;
    vertical-align: top;
  }
  & td.left {
    text-align: left;
    vertical-align: middle;
  }
  & td.right {
    text-align: right;
    vertical-align: middle;
  }
  & td.bottom_right {
    text-align: right;
    vertical-align: bottom;
  }
  & td.no-border-t {
    border-top: none;
  }
  & td.no-border-b {
    border-bottom: none;
  }
  & td.no-border-tb {
    border-top: none;
  }
  & td.no-border-lr {
    border-left: none;
    border-right: none;
  }
  & td.border-b-only {
    border: none;
    border-bottom: 1px solid #000;
  }
  & td.border-t-only {
    border: none;
    border-top: 1px solid #000;
  }
  & td.no-border-all {
    border: none;
  }
  & td.jextra {
    padding-left: 30px;
  }
  & td.bold-top {
    border-top: 3px solid #000;
  }
  & td.bold-bottom {
    border-bottom: 3px solid #000;
  }
  & td.bold-left {
    border-left: 3px solid #000;
  }
  & td.bold-right {
    border-right: 3px solid #000;
  }
  & td.bold-thick-right {
    border-right: 12px solid silver;
  }
  & td.dot-bottom {
    border-bottom: 1px dotted #000;
  }
  & td.no-border-l {
    border-left: none;
  }
  & td.no-border-r {
    border-right: none;
  }
}

table.print-table-nb {
  width: 890px;
  border-collapse: collapse;
  border-spacing: 0;
  //border: 1px solid #909090;
  //font-size: 12px;
  & tr {
    //border: 1px solid #909090;
  }
  & td {
    vertical-align: top;
    //border: 1px solid #909090;
    padding: 4px 8px;
    & .label {
      min-height: 22px;

    }
    & .value {
      min-height: 22px;
      font-weight: bold;
      white-space: pre-line;
    }
  }
  & td.no-border {
    border-top: none;
    border-bottom: none;
  }
  & td.center {
    text-align: center;
    vertical-align: middle;
  }
  & td.right {
    text-align: right;
    vertical-align: middle;
  }
  & td.no-border-t {
    border-top: none;
  }
  & td.no-border-b {
    border-bottom: none;
  }
  & td.no-border-tb {
    border-top: none;
  }
  & td.border-b-only {
    border: none;
    border-bottom: 1px solid #000;
  }
  & td.border-t-only {
    border: none;
    border-top: 1px solid #000;
  }
  & td.no-border-all {
    border: none;
  }
}

table.view-table {
  width: 400px;
  border-collapse: collapse;
  border-spacing: 0;
  & td {
    vertical-align: top;
    border: 1px solid #000;
    padding: 0 8px;
    & .label {
      min-height: 1em;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    & .value {
      min-height: 1em;
      font-weight: bold;
      padding-bottom: 4px;
      white-space: pre-line;
    }
  }
  & td.center {
    text-align: center;
    vertical-align: middle;
  }
  & td.centerTop {
    text-align: center;
    vertical-align: top;
  }
  & td.right {
    text-align: right;
    vertical-align: middle;
  }
  & td.bottom_right {
    text-align: right;
    vertical-align: bottom;
  }
  & td.no-border-all {
    border: none;
  }
  & td.no-border-t {
    border-top: none;
  }
  & td.no-border-b {
    border-bottom: none;
  }
  & td.no-border-rl {
    border-right: none;
    border-left: none;
  }
}

.print-body {
  margin: 1cm;
}

.air-manifest-print-container {
  margin: 0;
}

@media print {
  .air-manifest-print-container {
    margin: 0;
    width: calc(100% - 2cm);
  }
}

.color-label {
  color: #646777;
}

@media print {
  html, body { height: initial !important; overflow: initial !important; }
  .page-break {page-break-after: always; margin-top: 2cm; margin-bottom: 1cm}
}

hr.line {
  border: 1px solid;
}

.invoice_label {
  font-weight: bold;
}

.left-space {
  padding-left:15em
}

.pointColor {
  background-color: silver;
}

.card-body {
  background-color: #f1f1f1 !important;
}

.form__form-group-field > input, select, textarea {
  background-color: #fff !important;
}

.form__form-group-field-no-label > input, select, textarea {
  background-color: #fff !important;
}

.date-picker input {
  background-color: #fff !important;
}

.required-color {
  background-color: #fcfdcb !important;
}

.not-required-color {
  background-color: #ffffff !important;
}

.card-body-color {
  background-color: #f1f1f1 !important;
}

.tooltip {
  position: absolute !important;
  z-index: 999999 !important;
  padding: 2px 8px !important;
  min-width: fit-content !important;
  max-width: 150px !important;
  white-space: nowrap !important;
}

.react-datepicker-wrapper {
  width: 100%;
  padding: 0;
  border: 0;
}

.smallLabel {
  font-size: 0.9em;
}

.rot1 {
  //position:absolute;
  transform: translate(10%) rotate(270deg);
  //top: 80px;
  //left: 785px;
  margin-right: 150px;
}

.rot2 {
  //position:absolute;
  transform: rotate(270deg);
  //top: 350px;
  //left: 715px;
}

.left-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.underLine {
  text-decoration: underline;
}

.notoSansKorean {
  font-family: 'Noto Sans KR', sans-serif;
}

.tooltip-inner {
  max-width: 1000px !important;
}

input.underline-only {
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-width: 1px;
}
