@for $i from 2 through 12 {
  $space: $i * 4;
  .m-#{$space} {margin: #{$space}px}
  .ml-#{$space} {margin-left: #{$space}px}
  .mt-#{$space} {margin-top: #{$space}px}
  .mr-#{$space} {margin-right: #{$space}px}
  .mb-#{$space} {margin-bottom: #{$space}px}
  .mx-#{$space} {margin-left: #{$space}px; margin-right: #{$space}px}
  .my-#{$space} {margin-top: #{$space}px; margin-bottom: #{$space}px}
  .p-#{$space} {padding: #{$space}px}
  .pl-#{$space} {padding-left: #{$space}px}
  .pt-#{$space} {padding-top: #{$space}px}
  .pr-#{$space} {padding-right: #{$space}px}
  .pb-#{$space} {padding-bottom: #{$space}px}
  .px-#{$space} {padding-left: #{$space}px; padding-right: #{$space}px}
  .py-#{$space} {padding-top: #{$space}px; padding-bottom: #{$space}px}
}
.pl-128 {padding-left: 128px}
.pb-64 {padding-bottom: 64px}
.ml-120 {margin-left: 120px}

.flex {
  display: flex;
  &.wrap {flex-wrap: wrap}
  &.left {justify-content: flex-start}
  &.center {justify-content: center}
  &.right {justify-content: flex-end}
  &.between {justify-content: space-between}
  &.around {justify-content: space-around}
  &.evenly {justify-content: space-evenly}
  &.top {align-items: flex-start}
  &.middle {align-items: center}
  &.bottom {align-items: flex-end}
}

.flex-column {
  display: flex;
  flex-direction: column;
  &.left {align-items: flex-start}
  &.center {align-items: center}
  &.right {align-items: flex-end}
}

@for $i from 1 through 12 {
  .flex-#{$i} {flex: #{$i}}
}

.w-full {width: 100%}
.w-half {width: 50%}
.w-oneThird {width: 33.333333%}
.w-quarter {width: 25%}
.w-4 {width: 4px !important;}
.w-8 {width: 8px !important;}
.w-12 {width: 12px !important;}
.w-24 {width: 24px !important;}
.w-36 {width: 36px !important;}
.w-40 {width: 40px !important;}
.w-48 {width: 48px !important;}
.w-60 {width: 60px !important;}
.w-70 {width: 70px !important;}
.w-96 {width: 96px !important;}
.w-100 {width: 100px !important; min-width: 100px; }
.w-128 {width: 128px !important;}
.w-152 {width: 152px !important;}
.w-250 {width: 250px !important;}
.w-500 {width: 500px !important;}
.w-8 {width: 8px}
.no-margin {margin: 0}
.border {border: 1px solid #c0c0c0}
