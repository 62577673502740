.inbox__emails-control-search {
  display: flex;
  //@include directify($directions) {
  //  #{directed('margin-right')}: 16px;
  //}

  input {
    height: 28px;
    @include directify($directions) {
      #{directed('padding-left')}: 10px;
      #{directed('border-top-left-radius')}: 18px;
      #{directed('border-bottom-left-radius')}: 18px;
    }
    transition: all 0.3s;
    border: 1px solid #dddddd;
    width: 150px;

    @include themify($themes) {
      color: themed('colorText');
      background: themed('colorHover');
    }

    &:focus {
      border-color: $color-accent;
      outline: none;

      & + .inbox__emails-control-search-icon {
        background-color: $color-accent;

        svg {
          fill: white;
        }
      }
    }

    &::-webkit-input-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
    &::-moz-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
    /* Firefox 19+ */
    &:-moz-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {

      @include themify($themes) {
        color: themed('colorIcon');
      }
    }
  }
}

.inbox__emails-control-search-icon {
  width: 28px;
  padding: 3px;
  height: 28px;
  @include directify($directions) {
    #{directed('border-top-right-radius')}: 18px;
    #{directed('border-bottom-right-radius')}: 18px;
  }
  transition: 0.3s;

  @include themify($themes) {
    background: #dddddd; //themed('colorFieldsBorder');
  }

  svg {
    height: 18px;
    width: 18px;
    margin-top: 0;
    transition: 0.3s;

    @include themify($themes) {
      fill: #eeeeee; //themed('colorIcon');
    }
  }
}

.inbox-search-icon {
  width: 28px;
  padding: 3px;
  height: 32px;
  transition: 0.3s;

  @include themify($themes) {
    background: #dddddd; //themed('colorFieldsBorder');
  }

  svg {
    height: 18px;
    width: 18px;
    margin-top: 0;
    transition: 0.3s;

    @include themify($themes) {
      fill: #eeeeee; //themed('colorIcon');
    }
  }
}
